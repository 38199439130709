import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// import { Location } from '@reach/router';
import Img from "gatsby-image";

import Layout from "../components/layout";
import Title from "../components/title";

export default function Company({ data }) {
  const pageData = {
    titleClass: "company",
    title: "会社概要",
    discription: "",
    image: 10,
  };
  const seodata = {
    title: "AVIELAN 会社概要",
    seo: {
      description: "アヴィエランの会社概要",
      image: {
        url: data.datoCmsImage.topimage[10].url,
      },
    },
    slug: "company",
  };
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="pankz">
        <Link to="/">トップ</Link>
        <p>会社概要</p>
      </div>
      <div className="topnews">
        <div className="topnewsimg">
          <Img fluid={data.datoCmsImage.topimage[10].news} alt="会社概要" />
          <p className="japanese">Company</p>
        </div>
        <div className="topnewslist company">
          <p>社名</p>
          <p>アヴィエラン株式会社</p>
          <p>インボイス番号</p>
          <p>T6180001080274</p>
          <p>本社</p>
          <p>〒465-0058 愛知県名古屋市名東区貴船1-346</p>
          <p>電話番号</p>
          <p>052-701-6711</p>
          <p>設立</p>
          <p>2007/4</p>
          <p>代表者</p>
          <p>小長井 健史</p>
          <p>資本金</p>
          <p>&yen;20,000,000</p>
          <p>取引検査機関</p>
          <p>
            名古屋市工業研究所
            <br />
            日本繊維製品品質技術センター
            <br />
            あいち産業科学技術総合センター
          </p>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    datoCmsImage {
      topimage {
        url
        news: fluid(
          maxWidth: 1800
          forceBlurhash: false
          imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
